import { AdjustmentTypePolicy } from "constants/charge/AdjustmentTypePolicy";
import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { CoaEntity } from "domain/entity/Company/CoaEntity";
import { EMPTY_MANUAL_CHARGE_DETAIL_ADJUSTMENT_REQUEST_ENTITY, ManualChargeDetailAdjustmentRequestEntity } from "domain/entity/ManualCharge/ManualChargeDetailAdjustmentRequestEntity";
import { EMPTY_MANUAL_CHARGE_DETAIL_ENTITY, ManualChargeDetailEntity } from "domain/entity/ManualCharge/ManualChargeDetailEntity";
import { EMPTY_MANUAL_CHARGE_HEADER_ENTITY, ManualChargeHeaderEntity } from "domain/entity/ManualCharge/ManualChargeHeaderEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ParameterDetailEntity } from "domain/entity/Parameter/ParameterDetailEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { ShiftRepository } from "domain/repository/Common/ShiftRepo";
import { CoaRepository } from "domain/repository/Company/CoaRepo";
import { CompanyCustomerInfoRepository } from "domain/repository/Company/CompanyCustomerInfoRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { CustomerRepository } from "domain/repository/Company/CustomerRepo";
import { TariffCoaMappingRepository } from "domain/repository/Document/TariffCoaMappingRepo";
import { ExchangeRateRepository } from "domain/repository/ExchangeRate/ExchangeRateRepo";
import { ManualChargeDetailRepository } from "domain/repository/ManualCharge/ManualChargeDetailRepo";
import { ManualChargeHeaderRepository } from "domain/repository/ManualCharge/ManualChargeHeaderRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffProposalRepository } from "domain/repository/TariffCode/TariffProposalRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import _ from "lodash";
import { updateAdjustmentValidationSchema } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailValidationSchema";
import { uomDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { nonOpsChgDtlUpperCaseFieldList, nonOpsChgDtlUpperCaseRemoveSpaceFieldList } from "presentation/constant/NonOps/NonOpsConstant";
import { createNonOpsDtlValidationSchema, createNonOpsHdrStdBillValidationSchema, createNonOpsHdrValidationSchema, updateNonOpsDtlValidationSchema, updateNonOpsHdrStdBillValidationSchema, updateNonOpsHdrValidationSchema } from "presentation/constant/NonOps/NonOpsValidationSchema";
import { ParameterCodeValue, ParameterDetailCodeValue } from "presentation/constant/Parameter/ParameterCodeValue";
import { Validation } from "presentation/constant/Validation";
import { DropdownProps } from "presentation/model/DropdownProps";
import { EMPTY_NON_OPS_DETAIL_MODEL, NonOpsDetailModel } from "presentation/model/NonOpsMaintenance/NonOpsDetailModel";
import { NonOpsHeaderModel } from "presentation/model/NonOpsMaintenance/NonOpsHeaderModel";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface NonOpsDetailVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<NonOpsDetailModel>> | ((value: SetStateAction<NonOpsDetailModel>) => void),
    ],
    hdrDispatch: [
        Dispatch<SetStateAction<NonOpsHeaderModel>> | ((value: SetStateAction<NonOpsHeaderModel>) => void),
    ]
    chargeTypeRepo: ChargeTypeRepository,
    companyRepo: CompanyRepository,
    manualChargeHeaderRepo: ManualChargeHeaderRepository,
    manualChargeDetailRepo: ManualChargeDetailRepository,
    masterDataRepo: MasterDataRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    tariffTypeRepo: TariffTypeRepository,
    parameterDetailRepo: ParameterDetailRepository,
    shiftRepo: ShiftRepository,
    tariffProposalRepo: TariffProposalRepository,
    customerRepo: CustomerRepository,
    companyCustomerInfoRepo: CompanyCustomerInfoRepository,
    exchangeRateRepo: ExchangeRateRepository,
    tariffCoaMappingRepo:  TariffCoaMappingRepository,
    coaRepo: CoaRepository,
}

export const NonOpsDetailVM = ({ dispatch, hdrDispatch,chargeTypeRepo,  companyRepo, 
    manualChargeHeaderRepo,manualChargeDetailRepo,masterDataRepo,standardTariffCodeRepo,tariffTypeRepo,
    parameterDetailRepo,shiftRepo,tariffProposalRepo,customerRepo, companyCustomerInfoRepo,
    exchangeRateRepo,tariffCoaMappingRepo,coaRepo}: NonOpsDetailVMProps) => {
    const [nonOpsDtlDispatch] = dispatch;
    const [nonOpsHdrDispatch] = hdrDispatch;
    
    
    const onPageInit = (currentManChgHdr: ManualChargeHeaderEntity | null) => {
        const manChargeHdr = currentManChgHdr ?? EMPTY_MANUAL_CHARGE_HEADER_ENTITY;

        let isEditable = !!(currentManChgHdr?.id) && (currentManChgHdr?.hdrState === ChargeConstantCollection.manHdrState.NIL 
            || currentManChgHdr?.hdrState === ChargeConstantCollection.manHdrState.STD);

        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                currentManualChargeHeader: {
                    ...manChargeHdr,
                    chargeCategory: 'NO',
                    chargeType: 'NO',
                },
                selectedManualChargeDetailRows: [],
                viewState:{
                    ...prevState.viewState,
                    isAdd: currentManChgHdr?.id === null,
                    isEditable: isEditable,
                    isRead: currentManChgHdr?.id !== null,
                    editingHeader: {
                        ...manChargeHdr,
                        chargeCategory: 'NO',
                        chargeType: 'NO',
                    },
                }
            }
        })
    }

    const onCloseScreen = () => {
        nonOpsDtlDispatch(prevState => {
            return {
                ...EMPTY_NON_OPS_DETAIL_MODEL,   
            }
        })

        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState,
                manualChargeHeaders: [],
                isShowDetail: false,
                isBackFromDetail: true,
                isAllowAutoSearch: true,
            }
        })
    }

    // const onReset = () => {
    //     nonOpsDtlDispatch(prevState => {
    //         return {
    //             ...prevState,   
    //             hdrState:{
    //                 ...prevState.hdrState,
    //                 editingHeader: {
    //                     ...prevState.currentManualChargeHeader,
    //                 }
    //             }
    //         }
    //     })
    // }

    // const onResetDetail = () => {
    //     nonOpsDtlDispatch(prevState => {
    //         const prevDtl = prevState.viewState.isAdd? EMPTY_MANUAL_CHARGE_DETAIL_ENTITY : prevState.selectedManualChargeDetailRows[0]
    //         return {
    //             ...prevState,                  
    //             viewState: {
    //                 ...prevState.viewState,
    //                 updatedRows: [],
    //                 currentSelectedData: {
    //                     ...prevDtl
    //                 }
    //             }
    //         }
    //     })
    // }

    const onShowLoading = () => {
        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedManualChargeDetails = (rows:any[]) => {
        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                selectedManualChargeDetailRows: rows                       
            }
        })
    }

    const onSelectedSingleChargeDetail = (row: ManualChargeDetailEntity) => {
        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState:{
                    ...prevState.viewState,
                    currentSelectedData: row
                }                   
            }
        })
    }

    const onSearch = async (manChgHdrId: number) => {
        // DomUtils.addWithSearchClass();
        manualChargeDetailRepo.getManualChargeDetailByHdrId(manChgHdrId).then((data) => {
            nonOpsDtlDispatch(prevState => {
                return {
                    ...prevState,
                    manualChargeDetails: data,
                    currentManualChargeHeader: data[0].manualChargeHdr?data[0].manualChargeHdr:prevState.currentManualChargeHeader,
                    selectedManualChargeDetailRows: [],
                    viewState: {
                        ...prevState.viewState,
                        editingHeader: {
                            ...(data[0].manualChargeHdr?data[0].manualChargeHdr:prevState.currentManualChargeHeader),
                            chargeCategory: 'NO',
                            chargeType: 'NO',
                        },
                        currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                    }
                }
            })
        })
    }

    const loadDropdownOption = async() => {
        await customerRepo.getCustomerActive().then(
            customers => {
                let customerCodeDropdownOptions = customers?.map((customer) => ({
                    dropdownLabel: customer.customerCode + ' ' + customer.customerDesc1,
                    tagLabel: customer.customerCode,
                    value: customer.customerCode,
                })) ?? []
                customerCodeDropdownOptions = _.orderBy(customerCodeDropdownOptions, "dropdownLabel");

                nonOpsDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        customerCodeDropdownOptions: customerCodeDropdownOptions,
                    }
                }))
            }
        )

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                nonOpsDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompDropdownOptions: companyCodeDropdownOptions,
                    }
                }))
            }
        )

        await exchangeRateRepo.getAllCurrencies().then(
            currencies => {
                let currencyDropdownOptions = currencies?.map((currency) => ({
                    dropdownLabel: currency.currencyCode,
                    tagLabel: currency.currencyCode,
                    value: currency.currencyCode,
                })) ?? []
                currencyDropdownOptions = _.orderBy(currencyDropdownOptions, "dropdownLabel");

                nonOpsDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        currencyDropdownOptions: currencyDropdownOptions,
                    }
                }))
            }
        )

        // await parameterDetailRepo.getAllParameterDtlsByParameterCode("BILLING_CYCLE").then(
        //     parDtls => {
        //         const billingCycleDropdownOptions = parDtls?.map((parDtl) => ({
        //             dropdownLabel: parDtl.parameterDtlCode,
        //             tagLabel: parDtl.parameterDtlCode,
        //             value: parDtl.parameterDtlCode,
        //         })) ?? []

        //         nonOpsDtlDispatch(prevState => ({
        //             ...prevState,
        //             dynamicOptions: {
        //                 ...prevState.dynamicOptions,
        //                 billingCycleDropdownOptions: [
        //                     ...billingCycleDropdownOptions]
        //             }
        //         }))
        //     }
        // )

        await masterDataRepo.getMasterDataByKey(MasterDataType.TERMINAL).then(
            operatingTmls => {
                let operatingTmlDropdownOptions = operatingTmls?.map((opTml)=>({
                    dropdownLabel: opTml.code,
                    tagLabel: opTml.code,
                    value: opTml.code,
                })) ?? []
                operatingTmlDropdownOptions = _.orderBy(operatingTmlDropdownOptions, "dropdownLabel");
                nonOpsDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: [
                           ...operatingTmlDropdownOptions]
                    }
                }))
            }           
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.INTER_COMPANY).then(
            interCmps => {
                const interCmpDropdownOptions = interCmps?.map((interCmp)=>({
                    dropdownLabel: interCmp.code,
                    tagLabel: interCmp.code,
                    value: interCmp.code,
                })) ?? []

                nonOpsDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        interCmpCodeDropdownOptions: [ 
                            ...interCmpDropdownOptions
                        ]
                    }
                }))
            }           
        )

       await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {
                
                let newTariffs = _.orderBy(tariffs, ["tariffType","tariffCode"]);                
                let tariffTypeDropdownOptions:DropdownProps[] = [];
                let tariffCodeDropdownOptions:{[key:string]: DropdownProps[]} = {};
                let allTariffCodeeDropdownOptions:DropdownProps[] = [];

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t => 
                        t.value === tariff.tariffType);
                    if(!isTariffTypeExisted){
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t => 
                        t.value === tariff.tariffCode);

                    if(!isTariffCodeExisted){
                        allTariffCodeeDropdownOptions.push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }

                    if(tariff.tariffCode){
                        if(!tariffCodeDropdownOptions[tariff.tariffType]){
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })            

                nonOpsDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        taxTariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                        taxTariffCodeDropdownOptions: tariffCodeDropdownOptions,
                        allTariffCodeeDropdownOptions: allTariffCodeeDropdownOptions,
                    }
                }))
            }
        )
        
        // await tariffTypeRepo.getAllActiveTariffTypes().then(
        //      tarTypes => {
        //          const tariffTypeDropdownOptions = tarTypes.map((result) => ({
        //                  dropdownLabel: result.tariffType,
        //                  tagLabel: result.tariffType,
        //                  value: result.tariffType,
        //          })) ?? [];
 
        //          manChgDtlDispatch(prevState => ({
        //              ...prevState,
        //              dynamicOptions: {
        //                  ...prevState.dynamicOptions,
        //                  tariffTypeDropdownOptions: tariffTypeDropdownOptions,
        //                  taxTariffTypeDropdownOptions: tariffTypeDropdownOptions,
        //              }
        //          }))
        //      }
        //  )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.ARTICLE_STMT_CODE).then(
            parDtls => {
                const vatArticleStatementCodes = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                nonOpsDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        articleStatementCodeDropdownOptions: [...vatArticleStatementCodes]
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.TAX_CODE).then(
            parDtls => {
                const taxCodes = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                nonOpsDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        taxCodeDropdownOptions: taxCodes
                    }
                }))
            }
        )
        
        //get accResumeTerminalReasonCode DropdownOption from parameter
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("ACCT_TML_REASON_CODE").then(
            accReasonCodeList => {
                const accReasonCodeDropdownOption = accReasonCodeList?.map((reasonCode) => ({
                    dropdownLabel: reasonCode.parameterDtlCode,
                    tagLabel: reasonCode.parameterDtlCode,
                    value: reasonCode.parameterDtlCode,
                })) ?? []

                nonOpsDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        reasonCodeDropdownOptions: accReasonCodeDropdownOption
                    }
                }))
            }
        )
    }

    const onInitDefaultValue = () => {
        nonOpsDtlDispatch(prevState => ({
            ...prevState,    
            viewState: {
                ...prevState.viewState,
                currentSelectedData: {
                    ...prevState.viewState.currentSelectedData,
                    tariffType: prevState.viewState.currentSelectedData.id!==null? prevState.viewState.currentSelectedData.tariffType 
                        : (prevState.dynamicOptions.tariffTypeDropdownOptions.length > 0 ? prevState.dynamicOptions.tariffTypeDropdownOptions[0].value : ''),
                    tariffCode: prevState.viewState.currentSelectedData.id!==null? prevState.viewState.currentSelectedData.tariffCode 
                            : (prevState.dynamicOptions.tariffCodeDropdownOptions[prevState.dynamicOptions.tariffTypeDropdownOptions[0].value].length > 0 ? 
                                prevState.dynamicOptions.tariffCodeDropdownOptions[prevState.dynamicOptions.tariffTypeDropdownOptions[0].value][0].value : ''),
                }
            }
        }))
    }

    const onAdd = () => {
        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    isShowDetailInfo: true,
                    isAdd: true,
                    isRead: false,
                    isSaveClicked: false,
                    currentSelectedData: {
                        ...EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                        dummyKey: _.uniqueId(),
                    },
                }
            }
        })
    }
    
    const onEdit = (manHdr: ManualChargeHeaderEntity, manualChargeDetails: ManualChargeDetailEntity[]) => {
        let isAdjustmentRead:boolean = false;
        if (manualChargeDetails && manualChargeDetails.length > 0) {
            if (manualChargeDetails[0].adjType === AdjustmentTypePolicy.ADJ_TYPE_ADJUSTMENT) {
                isAdjustmentRead = true;
            }
        }
        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    isAdd: false,
                    isRead: false,
                    isAdjustmentRead: isAdjustmentRead,
                    isEditable: true, 
                    editingHeader: {
                        ...manHdr,
                        chargeCategory: 'NO',
                        chargeType: 'NO',
                    },
                }
            }
        })
    }

    const onCancel = () => {
        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    editingHeader:{
                        ...EMPTY_MANUAL_CHARGE_HEADER_ENTITY,
                    },
                    currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                    isRead: true,
                    isEditable: true,
                    allowAutoRefresh: false,
                    allFormState: {},
                }
            }
        })
    }

    const onCancelDetail = () => {
        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                    isShowDetailInfo: false,
                    isEditable: true,
                    allowAutoRefresh: false,
                    isAdd: false,
                    allFormState: {},
                }
            }
        })
    }

    const onSaveClicked =() => {
        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onRowClick = (row: ManualChargeDetailEntity) => {
        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: row,
                    isShowDetailInfo: true,
                    isAdd: false,                    
                    allFormState: {},
                }                        
            }
        });
    }

    const onSave = async (currentHdr: ManualChargeHeaderEntity, dtls: ManualChargeDetailEntity[], isAdd: boolean ) => {
        const createHdrValidationSchema = currentHdr.hdrState === 'STD' ? createNonOpsHdrStdBillValidationSchema : createNonOpsHdrValidationSchema;
        const updateHdrValidationSchema = currentHdr.hdrState === 'STD' ? updateNonOpsHdrStdBillValidationSchema : updateNonOpsHdrValidationSchema;
        
        const valHdrResult = await Validation(isAdd ? createHdrValidationSchema : updateHdrValidationSchema).ValidateFormOnly(currentHdr);
        
        if (valHdrResult ) {
            let allValResult: {[x: string]: string} = {};  
            if (valHdrResult) {  
                allValResult = { ...allValResult, ...valHdrResult,
                    mandatoryCheckFail: 'Please input the missing value.' };  
            }  



            nonOpsDtlDispatch(prevState => {
                return {
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        allFormState: {
                            ...allValResult,
                        },

                    }                        
                }
            });
            return allValResult;
        } else {
            const savedManHdr = await manualChargeDetailRepo.saveAll({
                manChgHdr: currentHdr,
                manChgDtls: dtls,
                chargeCategory: "NO"
            }).then((res) => {
                if(res.data){
                    const manHdr = res.data as ManualChargeHeaderEntity;

                    nonOpsDtlDispatch(prevState => {
                        return {
                            ...prevState,
                            manualChargeDetails: [],
                            currentManualChargeHeader: {
                                ...manHdr,
                            },
                            viewState:{
                                ...prevState.viewState,
                                isAdd: false,
                                isRead: true,
                                isEditable: true,
                                isSaveClicked: false,
                                editingHeader:{
                                    ...EMPTY_MANUAL_CHARGE_HEADER_ENTITY,
                                    chargeCategory:'NO',
                                    chargeType:'NO',
                                },
                                currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                                lastEditRowId: '',
                                allowAutoRefresh: false,
                            }                            
                        }
                    })    
                    
                    return manHdr;
                }else{
                    throw new Error(res.data as string)
                }
            })
    
            return savedManHdr;
            
        }       
        
    }

    const onSaveDetail = async (currentDtl: ManualChargeDetailEntity, isAdd: boolean ) => {
        // const createHdrValidationSchema = currentHdr.hdrState === 'STD' ? createNonOpsHdrStdBillValidationSchema : createNonOpsHdrValidationSchema;
        // const updateHdrValidationSchema = currentHdr.hdrState === 'STD' ? updateNonOpsHdrStdBillValidationSchema : updateNonOpsHdrValidationSchema;
        
        // const valHdrResult = await Validation(isAdd ? createHdrValidationSchema : updateHdrValidationSchema).ValidateFormOnly(currentHdr);
        const newDtl:ManualChargeDetailEntity = {...currentDtl, chargeQty: (currentDtl.chargeQty && !!currentDtl.chargeQty ? currentDtl.chargeQty : null)};
        const valResult = await Validation(isAdd ? createNonOpsDtlValidationSchema : updateNonOpsDtlValidationSchema).ValidateFormOnly(newDtl);
        
        if (valResult) {
            let allValResult: {[x: string]: string} = {};  

            if (valResult) {  
                allValResult = { ...allValResult, ...valResult,                    
                            mandatoryCheckFail: 'Please input the missing value.'
                };  
            }



            nonOpsDtlDispatch(prevState => {
                return {
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        allFormState: {
                            ...allValResult,
                        },

                    }                        
                }
            });
            return allValResult;
        } else {
            
            if(newDtl.tariffType && newDtl.tariffCode){
                await onValidateCoa(newDtl.tariffType,newDtl.tariffCode,false,newDtl);
            }
            
            if(newDtl.vatTarType && newDtl.vatTarCode){
                await onValidateCoa(newDtl.vatTarType,newDtl.vatTarCode,true,newDtl);
            }

            nonOpsDtlDispatch(prevState => {
                const oldDtls = prevState.manualChargeDetails;
                let newDtls = oldDtls.filter(dtl => (dtl.id !== null && dtl.id !== newDtl.id) 
                    || (dtl.id === null && dtl.dummyKey !== newDtl.dummyKey));
                newDtls.push(newDtl);

                return {
                    ...prevState,      
                    manualChargeDetails: newDtls,     
                    viewState:{
                        ...prevState.viewState,
                        isAdd: false,
                        isRead: false,
                        isEditable: true,
                        isSaveClicked: false,
                        currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                        allowAutoRefresh: false,
                        isShowDetailInfo: false,
                    }                    
                }
            })        

            return currentDtl;    
        }       
    }

    const onApply = async (manChgHdr: ManualChargeHeaderEntity, manChgDtls: ManualChargeDetailEntity[]) => {
        await manualChargeDetailRepo.applyManualChargeDetail({
            chargeCategory:'NO',
            manChgHdr,
            manChgDtls,
        }).then((res) =>{

        })
    }

    const onDelete = async (selectedRows: ManualChargeDetailEntity[]) => {
        
        await manualChargeDetailRepo.deleteManualChargeDetails(
            selectedRows.map(row => row.id) as number[]
        ).then((res) => {

        })
    }
    
    const onHeaderFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
      if (_.isArray(val)) {
        val = _.uniq(val?.map((item: any) => item.value || item.key));
      }     
      return nonOpsDtlDispatch(prevState => {
        return {
            ...prevState,
                viewState: {
                    ...prevState.viewState,
                    allFormState: {
                        ...prevState.viewState.allFormState,
                        [fieldKey]: '' ,
                        validateRangeDate: fieldKey === 'stdBillEffectiveDate' ? '' : prevState.viewState.allFormState?.validateRangeDate??''
                    },
                    editingHeader: {
                        ...prevState.viewState.editingHeader,
                        [fieldKey]: val,
                    }
                },
            }          
        })
    }
    
    const onHeaderInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        nonOpsDtlDispatch(prevState => ({
            ...prevState,
            viewState:{
                ...prevState.viewState,
                editingHeader: {
                    ...prevState.viewState.editingHeader,
                    [fieldName]: e.target.value,
                }
            }
        }))
    };
    

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        let tarCode: any;
        let vatTarCode: any;
      if (_.isArray(val)) {
        val = _.uniq(val?.map((item: any) => item.value || item.key));
      }     
      return nonOpsDtlDispatch(prevState => {
        // var regex;
        // if(nonOpsChgDtl2dpNumberFieldList.find(e => e === fieldKey)){
        //     regex = /^\d*(\.\d{0,2})?$/;
        //     if (!regex.test(val)) { 
        //         val = prevState.viewState.currentSelectedData[fieldKey] ;
        //     }
        // }else if(nonOpsChgDtl4dpNumberFieldList.find(e => e === fieldKey)){
        //     regex = /^\d*(\.\d{0,4})?$/;
        //     if (!regex.test(val)) { 
        //         val = prevState.viewState.currentSelectedData[fieldKey] ;
        //     }
        // }else if(nonOpsChgDtlPositiveIntegerNumberFieldList.find(e => e === fieldKey)){
        //     regex = /^\d*$/;
        //     if (!regex.test(val)) { 
        //         val = prevState.viewState.currentSelectedData[fieldKey] ;
        //     }
        // }else 
        if(nonOpsChgDtlUpperCaseRemoveSpaceFieldList.find(e => e === fieldKey)){
            val = (val as string).toUpperCase().replace(/\s+/g, '');
        } else if(nonOpsChgDtlUpperCaseFieldList.find(e => e === fieldKey)){
            val = (val as string).toUpperCase();
        }
        if(fieldKey ==='tariffType'){
            tarCode = prevState.dynamicOptions.tariffCodeDropdownOptions[val]?prevState.dynamicOptions.tariffCodeDropdownOptions[val][0].value:"";
        }
        if(fieldKey ==='vatTarType'){
            vatTarCode = prevState.dynamicOptions.taxTariffCodeDropdownOptions[val]?prevState.dynamicOptions.taxTariffCodeDropdownOptions[val][0].value:"";
        }
        return {
            ...prevState,
            viewState: {
                ...prevState.viewState,
                currentSelectedData: {
                    ...prevState.viewState.currentSelectedData,
                    [fieldKey]: val,
                    tariffCode:tarCode && tarCode!==null ? tarCode : prevState.viewState.currentSelectedData.tariffCode,   
                    vatTarCode:vatTarCode && vatTarCode!==null ? vatTarCode : prevState.viewState.currentSelectedData.vatTarCode       
                },
                allFormState: {
                    ...prevState.viewState.allFormState,
                    [fieldKey]: '' 
                },
                allowAutoRefresh: !prevState.viewState.allowAutoRefresh && fieldKey ==='vatOneOffTaxCode' ? true : prevState.viewState.allowAutoRefresh,
            }            
        }
      });
    }

    
    const onCntrTextAreaChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
      let val: any = fieldValue;
      let cntrCount:number;
      if(fieldValue){
        const arr = fieldValue.toString().split(';');
        if (arr[arr.length - 1] === '') {  
            arr.pop();  
        } 
        cntrCount = arr.length
      }
      return nonOpsDtlDispatch(prevState => {
        return {
            ...prevState,
            viewState: {
                ...prevState.viewState,
                currentSelectedData: {
                    ...prevState.viewState.currentSelectedData,
                    [fieldKey]: val,
                    chargeQty: cntrCount,
                }
            }            
        }
      });
    }

    const onRadioChange = (e: any, fieldName: string) => {
        if(e.checked){

            nonOpsDtlDispatch(prevState => {
                const nonOpsHdr = {...prevState.viewState.editingHeader};

                if(e.key === 'NONOPS'){
                    nonOpsHdr.hdrState = "NIL";
                    nonOpsHdr.stdBillBillingCycle = null;
                }else{
                    nonOpsHdr.orderNo = null;
                    nonOpsHdr.hdrState = "STD";
                }

                const obj: NonOpsDetailModel = {
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        editingHeader: {
                            ...nonOpsHdr,
                            [fieldName]: e.key
                        }
                    }
                };
                return { ...obj }
            })
        }
    };

    const onGetShift = async (opsDate: Date) => {
        shiftRepo.getShiftCodeMappingByOpsDate({
            opsDate: opsDate
        }).then((data)=>{
            nonOpsDtlDispatch(prevState => ({
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: {
                        ...prevState.viewState.currentSelectedData,
                        shiftCode: data.shiftCode,
                    }
                }
            }))
        })
    }

    const onGetUom = async (companyCode:string | null, opsDate: Date,tariffType: string, tariffCode: string,
        fromDate: Date,toDate: Date, operatingTml: string | null ) => {
        return await tariffProposalRepo.searchDefaultQtyUomByTariff({
            companyCode: companyCode,
            opsDate:opsDate,
            tariffType: tariffType,
            tariffCode: tariffCode,
            fromDate: fromDate,
            toDate:toDate,
            operatingTml:operatingTml,
        }).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                nonOpsDtlDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"getUOMFail": data.toString()}
                    };
                });
                return {"getUOMFail": data.toString()};
            } else {
                const uom = data;
                if(uomDropdownOption.find(u => u.value === uom)) {
                    nonOpsDtlDispatch(prevState => ({
                        ...prevState,
                        viewState: {
                            ...prevState.viewState,
                            currentSelectedData: {
                                ...prevState.viewState.currentSelectedData,
                                individualChargeQtyUom: uom,
                            }
                        }
                    }))
                    return {"getUOMSuccess":"successful"};
                }
            }
        }).catch(error => {
            nonOpsDtlDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"getUOMFail": error.message}                        
                }
            });
            return {"getUOMFail": error.message};
        });
    }

    const onRefreshDefaultTarCode = (taxCode: string | null,tariffTypeDropdownOptions:DropdownProps[],tariffCodeDropdownOptions:DropdownProps[],
        articleStatementCodeDropdownOptions:DropdownProps[]) => {
        taxCode && parameterDetailRepo.getParameterThirdDtlByParaCodeAndParaDtlCode(ParameterCodeValue.TAX_CODE,
            taxCode).then((data)=> {

            const vatTarType = getTaxDetail(data,ParameterDetailCodeValue.TAX_TAR_TYPE,tariffTypeDropdownOptions);
            const vatTarCode = getTaxDetail(data,ParameterDetailCodeValue.TAX_TAR_CODE,tariffCodeDropdownOptions);
            const vatArtStCode = getTaxDetail(data,ParameterDetailCodeValue.ARTICLE_STATEMENT,articleStatementCodeDropdownOptions);
            const taxPayTerm = getTaxDetail(data,ParameterDetailCodeValue.TAX_PAYMENT_TERM,null);
            const taxPayRefDate = getTaxDetail(data,ParameterDetailCodeValue.TAX_PAYMENT_REFERENCE_DATE,null);
            const taxPercentage = getTaxDetail(data,ParameterDetailCodeValue.TAX_PERCENTAGE,null);
            const taxDtlDesc1= getTaxDetail(data,ParameterDetailCodeValue.TAX_DETAIL_DESC,null);
            nonOpsDtlDispatch(prevState => ({
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: {
                        ...prevState.viewState.currentSelectedData,
                        vatTarType: vatTarType??'',
                        vatTarCode: vatTarCode??'',
                        vatArticleStatementCode: vatArtStCode??'',
                        vatDays: taxPayTerm? Number(taxPayTerm) : null,
                        paymentReferenceDate: taxPayRefDate??'',
                        vatPercentage: taxPercentage? Number(taxPercentage):null,
                        vatDesc1: taxDtlDesc1??'',
                    }
                }
            }))

            return vatArtStCode;
        }).then((vatArtStCode)=>{
            vatArtStCode && parameterDetailRepo.getParameterSecondDtlByParaCodeAndParaDtlCode(ParameterCodeValue.ARTICLE_STMT_CODE,
                vatArtStCode).then((data)=> {
                
                nonOpsDtlDispatch(prevState => ({
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        currentSelectedData: {
                            ...prevState.viewState.currentSelectedData,
                            vatArticleStatement: data?.parameterDtlDesc??null,
                        }
                    }
                }))
    
            })
        })

        taxCode && parameterDetailRepo.getParameterSecondDtlByParaCodeAndParaDtlCode(ParameterCodeValue.TAX_CODE,
            taxCode).then((data)=> {
            
            nonOpsDtlDispatch(prevState => ({
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: {
                        ...prevState.viewState.currentSelectedData,
                        vatTransactionType: data?.parameterDtlDesc??null,
                    }
                }
            }))

        })


    }

    const getTaxDetail = (data: ParameterDetailEntity[], codeValue: string, dropdownOptions: DropdownProps[] | null) => {  
        const dtl = data.find(dtl => dtl.parameterDtlCode === codeValue);  
        if (dtl?.activeInd === 'Y') {  
            if(dropdownOptions === null || (dropdownOptions && dropdownOptions.find(t => dtl.parameterDtlDesc === t.value))){
                return dtl.parameterDtlDesc;  
            }           
        }  
        return ''; 
    } 

    const onTariffCodeItemChanged = (tariffType: string | null, tariffCode: string  | null, fieldName: string) => {
        if(tariffType&&tariffCode){
            standardTariffCodeRepo.getTariffByTypeAndCode(tariffType,tariffCode).then((data) => {
                if(data){
                    updateCurrentSelectedData(fieldName, data.tariffCodeDesc); 
                }else{
                    updateCurrentSelectedData(fieldName, null);  
                }
            }).catch(()=>{
                updateCurrentSelectedData(fieldName, null);  
            })
        }else{
            updateCurrentSelectedData(fieldName, null);  
        }        
    }

    const updateCurrentSelectedData = (fieldName: string, newValue: any) => {  
        nonOpsDtlDispatch((prevState) => ({  
          ...prevState,  
          viewState: {  
            ...prevState.viewState,  
            currentSelectedData: {  
              ...prevState.viewState.currentSelectedData,  
              [fieldName]: newValue,  
            },  
        },  
        }));  
    }

    const onUpdateCoa = async (tariffType: string, tariffCode: string, isTax: boolean) => {  
        try { 
            const tarCoa = await tariffCoaMappingRepo.getTariffCoaByTarTypeCode(tariffType, tariffCode);  
      
            if (!tarCoa) {  
                throw new Error('No such Tariff COA Mapping record defined.')
            }  
      
            const coas = await coaRepo.searchCoas({  
                revenueAccountCode: tarCoa.revenueAccountCode,  
                costCenterCode: tarCoa.costCenterCode,  
                productServiceCode: tarCoa.productServiceCode,  
                salesChannelCode: tarCoa.salesChannelCode, 
                projectCode: tarCoa.projectCode,  
                spareCode: tarCoa.spareCode,
                countryCode:null 
            });  
      
            if (!coas || coas.length === 0) {  
                throw new Error('No such COA record found.')
            }  
      
            const intercompanyCodes = coas  
                .filter(coa => coa.intercompanyCode && coa.intercompanyCode !== '')  
                .map(item => item.intercompanyCode);  
      
            if (intercompanyCodes.length === 1) {  
                nonOpsDtlDispatch((prevState) => ({  
                    ...prevState,  
                    viewState: {  
                      ...prevState.viewState,  
                      currentSelectedData: {  
                        ...prevState.viewState.currentSelectedData,  
                        ...setCoaInfo(isTax,coas[0],intercompanyCodes[0])
                      },  
                    },  
                }));  
                
            } else if (intercompanyCodes.length > 1) {  
                nonOpsDtlDispatch((prevState) => ({  
                    ...prevState,  
                    viewState: {  
                      ...prevState.viewState,  
                      currentSelectedData: {  
                        ...prevState.viewState.currentSelectedData,  
                        ...setCoaInfo(isTax,coas[0],null)
                      },  
                    },  
                }));  

                const interCmps = intercompanyCodes.slice(0,5).join(",");
                throw new Error(`${interCmps}(Inter-company Code) meet the conditions. Please select one of them.`) 
            }  
        }catch(error){
 
            throw error;
        } 
    };

    const onValidateCoa = async (tariffType: string, tariffCode: string, isTax: boolean, manChgDtl: ManualChargeDetailEntity) => { 
        let msg = `The message of ${isTax? 'tax':''} COA inputted not match ${isTax? 'tax':''} tariff.`; 
        try { 
            const tarCoa = await tariffCoaMappingRepo.getTariffCoaByTarTypeCode(tariffType, tariffCode);  
      
            if (!tarCoa) {  
                throw new Error(msg);
            }  
      
            const coas = await coaRepo.searchCoas({  
                revenueAccountCode: tarCoa.revenueAccountCode,  
                costCenterCode: tarCoa.costCenterCode,  
                productServiceCode: tarCoa.productServiceCode,  
                salesChannelCode: tarCoa.salesChannelCode, 
                projectCode: tarCoa.projectCode,  
                spareCode: tarCoa.spareCode,
                countryCode:null
            });  
      
            if (!coas || coas.length === 0) {  
                throw new Error(msg);
            }  
      
            let pass = true; 
            if(isTax){
                if(manChgDtl.taxRevenueAccountCode !== coas[0].revenueAccountCode){
                    pass = false;
                }else if(manChgDtl.taxCostCenterCode !== coas[0].costCenterCode){
                    pass = false;
                }else if(manChgDtl.taxProductServiceCode !== coas[0].productServiceCode){
                    pass = false;
                }else if(manChgDtl.taxSalesChannelCode !== coas[0].salesChannelCode){
                    pass = false;
                }else if(manChgDtl.taxProjectCode !== coas[0].projectCode){
                    pass = false;
                }else if(manChgDtl.taxSpareCode !== coas[0].spareCode){
                    pass = false;
                }
            }else{
                if(manChgDtl.revenueAccountCode !== coas[0].revenueAccountCode){
                    pass = false;
                }else if(manChgDtl.costCenterCode !== coas[0].costCenterCode){
                    pass = false;
                }else if(manChgDtl.productServiceCode !== coas[0].productServiceCode){
                    pass = false;
                }else if(manChgDtl.salesChannelCode !== coas[0].salesChannelCode){
                    pass = false;
                }else if(manChgDtl.projectCode !== coas[0].projectCode){
                    pass = false;
                }else if(manChgDtl.spareCode !== coas[0].spareCode){
                    pass = false;
                }                
            }
            
            if(!pass){
                throw new Error(msg);
            }
              
        }catch(error){
 
            throw error;
        } 
    };

    const setCoaInfo = (isTax: boolean, data: CoaEntity, intercompanyCode: string | null) => {  
        if(isTax){
            return {  
                [`taxRevenueAccountCode`]: data.revenueAccountCode,  
                [`taxCostCenterCode`]: data.costCenterCode,  
                [`taxProductServiceCode`]: data.productServiceCode,  
                [`taxSalesChannelCode`]: data.salesChannelCode,  
                [`taxProjectCode`]: data.projectCode,  
                [`taxSpareCode`]: data.spareCode,  
                [`taxIntercompanyCode`]: intercompanyCode, 
            };  
        }else{
            return {  
                [`revenueAccountCode`]: data.revenueAccountCode,  
                [`costCenterCode`]: data.costCenterCode,  
                [`productServiceCode`]: data.productServiceCode,  
                [`salesChannelCode`]: data.salesChannelCode,  
                [`projectCode`]: data.projectCode,  
                [`spareCode`]: data.spareCode,  
                [`intercompanyCode`]: intercompanyCode, 
            };
        }
        
    }  
      
    const onCompanyItemChanged = async (companyCode: string | null) => {
        if(companyCode){
            companyCustomerInfoRepo.getCompanyCustomerInfo(companyCode).then(
                cmpCusts => {
                    let customerCodeDropdownOptions = cmpCusts?.map(cmpCust => cmpCust.customer).map((customer) => ({
                        dropdownLabel: customer.customerCode + customer.customerDesc1,
                        tagLabel: customer.customerCode,
                        value: customer.customerCode,
                    })) ?? []
                    customerCodeDropdownOptions = _.orderBy(customerCodeDropdownOptions, "dropdownLabel");
    
                    nonOpsDtlDispatch(prevState => ({
                        ...prevState,
                        searchCounter: prevState.searchCounter + 1,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            customerCodeDropdownOptions: customerCodeDropdownOptions,
                        },  
                        viewState: {
                            ...prevState.viewState,
                            editingHeader: {
                                ...prevState.viewState.editingHeader,
                                customerCode: customerCodeDropdownOptions.length > 0 ? customerCodeDropdownOptions[0].value : '',
                                customerName: '',
                            }
                        }
                    }))
                }
            )
        }else{
            customerRepo.getCustomerActive().then(
                customers => {
                    let customerCodeDropdownOptions = customers?.map((customer) => ({
                        dropdownLabel: customer.customerCode + customer.customerDesc1,
                        tagLabel: customer.customerCode,
                        value: customer.customerCode,
                    })) ?? []
                    customerCodeDropdownOptions = _.orderBy(customerCodeDropdownOptions, "dropdownLabel");
    
                    nonOpsDtlDispatch(prevState => ({
                        ...prevState,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            customerCodeDropdownOptions: customerCodeDropdownOptions,
                        }
                    }))
                }
            )
        }
    }

     const onCustomerItemChanged = async (customerCode: string | null) => {  
        let customerName = ''; 
      
        if (customerCode) {  
            try {  
                const data = await customerRepo.getCustomerByCode(customerCode);  
                customerName = data.customerDesc1 + " " + data.customerDesc2;  
            } catch (error) {  
  
            }  
        }  
      
        nonOpsDtlDispatch(prevState => ({  
            ...prevState,
            viewState: {
                ...prevState.viewState,
                editingHeader: {
                    ...prevState.viewState.editingHeader,
                    customerName: customerName,
                }
            }
        }));  
    }

    const onAdjustment = async (selectedRows: ManualChargeDetailEntity[], adjEntity:ManualChargeDetailAdjustmentRequestEntity) => {
        if (!selectedRows || selectedRows.length <= 0) return;
        const newAdjEntity:ManualChargeDetailAdjustmentRequestEntity = {
            ...adjEntity,
            manualDtlIds: (selectedRows.map(row => row.id) as number[])
        }
        
        return await manualChargeDetailRepo.adjustmentManualChargeDetails(newAdjEntity).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                nonOpsDtlDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"adjustmentNonOpsChargeFail": data.toString()}
                    };
                });
                return {"adjustmentNonOpsChargeFail": data.toString()};
            } else {
                nonOpsDtlDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"adjustmentNonOpsChargeSuccess":"successful"},
                        adjustmentEntity: {...EMPTY_MANUAL_CHARGE_DETAIL_ADJUSTMENT_REQUEST_ENTITY},
                        viewState: {
                            ...prevState.viewState,
                            isShowDetailInfo: false,
                        }
                    };
                });
                return {"adjustmentNonOpsChargeSuccess":"successful"};
            }
        }).catch(error => {
            nonOpsDtlDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"adjustmentNonOpsChargeFail": error.message}                        
                }
            });
            return {"adjustmentNonOpsChargeFail": error.message};
        })
    }

    const handleAdjustmentButtonClick = () => {
        nonOpsDtlDispatch(prevState => {
            return {
                ...prevState,
                isShowAdjustmentPanel: !prevState.isShowAdjustmentPanel, 
                adjustmentEntity: {...EMPTY_MANUAL_CHARGE_DETAIL_ADJUSTMENT_REQUEST_ENTITY}                      
            }
        });
    }

    const onValidationBeforeAdjustment = async(adjEntity:ManualChargeDetailAdjustmentRequestEntity) => {
        let result:boolean | null | undefined = undefined;

        result = await Validation(updateAdjustmentValidationSchema).ValidateFormOnly(
            adjEntity
        ).then(res => {
            if (res) {
                nonOpsDtlDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: res
                    }
                })
                return false;
            } else {
                return true;
            }
        }).catch(error => {
            return false;
        });
        return result;
    }

    const onDropdownChange = (e: any, fieldName: string, obj:any, objName:string) => {
        obj = {
            ...obj, 
            [fieldName]: e?.value ?? (_.isEmpty(e) ? 
             (fieldName === "subChgTypeList" ? [] : '') : e)};
        nonOpsDtlDispatch(prevState => ({
            ...prevState,
            [objName]: obj
        }))
    }

    return {
        onPageInit: onPageInit,
        onCloseScreen: onCloseScreen,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        updateSelectedManualChargeDetails: updateSelectedManualChargeDetails,
        onSelectedSingleChargeDetail: onSelectedSingleChargeDetail,
        onSearch,
        loadDropdownOption: loadDropdownOption,
        onAdd: onAdd,
        onEdit: onEdit,
        onCancel: onCancel,
        onCancelDetail: onCancelDetail,
        onRowClick: onRowClick,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onSaveDetail: onSaveDetail,
        onApply: onApply,
        onDelete: onDelete,
        onHeaderFieldChange: onHeaderFieldChange,
        onHeaderInputTextChange: onHeaderInputTextChange,
        onFieldChange: onFieldChange,
        onRadioChange: onRadioChange,
        onGetShift: onGetShift,
        onRefreshDefaultTarCode: onRefreshDefaultTarCode,
        onGetUom: onGetUom,
        onUpdateCoa: onUpdateCoa,
        onValidateCoa: onValidateCoa,
        onTariffCodeItemChanged: onTariffCodeItemChanged,
        onCntrTextAreaChange: onCntrTextAreaChange,
        onCompanyItemChanged: onCompanyItemChanged,
        onCustomerItemChanged: onCustomerItemChanged,
        onInitDefaultValue: onInitDefaultValue,
        onAdjustment: onAdjustment,
        handleAdjustmentButtonClick: handleAdjustmentButtonClick,
        onValidationBeforeAdjustment: onValidationBeforeAdjustment,
        onDropdownChange: onDropdownChange,
    }
}